// regenerator-runtime/runtime must be the first import or at least before exceljs
// prettier-ignore-start
import "regenerator-runtime/runtime"; // Required for ExcelJS to work with bare.min.js version (which in turn is required to prevent unsafe-eval)
// prettier-ignore-end
import { AttributTitlePublicApiMapper, CreateUpdateMeasureEffectMetaDtoV1, CreateUpdateMeasureFieldMetaDtoV1 } from "api-shared";
import ExcelJS, { Worksheet } from "exceljs";
import { TFunction } from "i18next";
import { IdeaEstimates } from "../view/ideas/hooks/useIdeaEstimates";
import { environment } from "./environment";
import {
    ConfigFieldFieldsKey,
    ConfigFieldNamesKey,
    ConfigMeasureConfigIdKey,
    ConfigTemplateTypeKey,
    IdFieldDisplayName,
    IdFieldInternalName,
    TemplateType,
    TitleFieldDisplayName,
    TitleFieldInternalName,
    convertAllowedValue,
} from "./excel";
import { tryTranslate } from "./translate";

/*
The Excel template is created with the ExcelJS library. The template consists of several sheets:
- The main sheet with the table for the data that should be imported
- A config sheet with settings and mappings
- A sheet with instructions
- Several sheets with lookup lists for the data validation

Creation of the main sheet is done by providing the column definitions derived from
the meta data (opps/measure master data) or the effect meta including effect fields for every month.

The config sheet is used to store information about the template type, (opt) measure type and mappings for the import.

Known Bugs corrupting the Excel file:
- Adding a note entry to a table breaks the Excel file.
- Set displayName on addTable
- headerRow: false
- add empty row
*/

type ColumnDefinition = {
    field: string; // Technical name
    name: string; // Human readable name
    type: "string" | "number" | "date" | "lookup" | "lookuplist" | "requiredId" | "id" | "title"; // Validation types for Excel data validation
    values?: string[]; // Values for lookup and lookuplist types
};

// Prevent lookup lists and config sheet from being visible in production
let hidden: ExcelJS.WorksheetState = "veryHidden";
if (!environment.isProduction) {
    hidden = "visible"; // "hidden";
}

const ExcelAppType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

// Creates a template for the given measure fields meta data.
export async function createMeasureFieldsTemplate(
    createMeta: CreateUpdateMeasureFieldMetaDtoV1,
    measureConfigId: number,
    translate: TFunction<"translation", undefined>,
) {
    const columnDefinitions: ColumnDefinition[] = [
        { field: IdFieldInternalName, name: IdFieldDisplayName, type: "number", values: [] },
        { field: TitleFieldInternalName, name: TitleFieldDisplayName, type: "title", values: [] },
    ];

    Object.keys(createMeta.fields)
        .filter((name) => name != "currency")
        .forEach((f) => {
            const field = createMeta.fields[f];

            // Skip lookup fields without values as those cannot be inserted
            if (
                (field.type === "lookuplist" || field.type === "lookup") &&
                (field.allowedValues === undefined || (!field.isCreatable && field.allowedValues.length === 0))
            ) {
                return;
            }

            // Get allowedValues names
            const values =
                field.allowedValues !== undefined
                    ? field.allowedValues
                          .map(convertAllowedValue)
                          .flat()
                          .filter((s) => s != "")
                    : undefined;
            const mappedTitle = field.name === "assignedTo" ? "assignedToId" : AttributTitlePublicApiMapper.toInternal(field.name);
            columnDefinitions.push({ field: field.field, name: tryTranslate(translate, mappedTitle), type: field.type, values });
        });

    return createTemplate(2, columnDefinitions, TemplateType.MeasureFields, measureConfigId);
}

// Creates a template for the given measure effects meta data and create effect categories for the date range.
export async function createMeasureEffectsTemplate(
    createMeta: CreateUpdateMeasureEffectMetaDtoV1,
    measureConfigId: number,
    startMonth: number,
    startYear: number,
    endMonth: number,
    endYear: number,
    translate: TFunction<"translation", undefined>,
) {
    const columnDefinitions: ColumnDefinition[] = [
        { field: IdFieldInternalName, name: IdFieldDisplayName, type: "requiredId", values: [] },
    ];

    // Effect categories
    Object.keys(createMeta.effectCategories).forEach((ec) => {
        const effectCategory = createMeta.effectCategories[ec];
        columnDefinitions.push({
            field: ec,
            name: tryTranslate(translate, ec),
            type: "lookup",
            values: effectCategory.values.map(convertAllowedValue).flat(),
        });
    });

    // Effect fields
    columnDefinitions.push({ field: `generation`, name: `Generation`, type: "lookup", values: createMeta.generations.map((g) => g.name) });
    columnDefinitions.push({ field: `currency`, name: `Currency`, type: "lookup", values: createMeta.currencies.map((c) => c.name) });

    const startDate = new Date(startYear, startMonth, 1);
    const endDate = new Date(endYear, endMonth, 1);
    for (let d = startDate; d <= endDate; d.setMonth(d.getMonth() + 1)) {
        const text = d.toLocaleString("default", { month: "2-digit" }) + "-" + d.getFullYear().toString();
        columnDefinitions.push({ field: `EC-${text}-initial`, name: `${text} Initial`, type: "string" });
        columnDefinitions.push({ field: `EC-${text}-effect`, name: `${text} Effect`, type: "string" });
    }

    return createTemplate(1, columnDefinitions, TemplateType.MeasureEffects, measureConfigId);
}

// Creates a template for the opps standard fields. This doesn't include the custom fields!
export async function createOppsTemplate(estimates: IdeaEstimates) {
    const columnDefinitions: ColumnDefinition[] = [
        { field: IdFieldInternalName, name: IdFieldDisplayName, type: "id" },
        { field: TitleFieldInternalName, name: TitleFieldDisplayName, type: "title" },
        { field: "description", name: "Description", type: "string" },
        {
            field: "potentialEstimate",
            name: "Potential Estimate",
            type: "lookup",
            values: estimates.potentialEstimates.map((e) => e.label),
        },
        { field: "timeEstimate", name: "Time Estimate", type: "lookup", values: estimates.timeEstimates.map((e) => e.label) },
        { field: "effortEstimate", name: "Effort Estimate", type: "lookup", values: estimates.effortEstimates.map((e) => e.label) },
    ];

    return createTemplate(2, columnDefinitions, TemplateType.Opps);
}

// Create the Excel template for the given definitions. Freezes the first `frozenColumns` columns.
async function createTemplate(
    frozenColumns: number,
    columnDefinitions: ColumnDefinition[],
    templateType: TemplateType,
    measureConfigId?: number,
) {
    const workbook = createBasicWorkbook();

    // Instructions sheet
    const instructionsSheet = workbook.addWorksheet("Instructions", {
        properties: { tabColor: { argb: "FF063C5B" } },
        views: [{ showGridLines: false }],
    });
    // Add some basic instructions
    instructionsSheet.getCell("B2").value = "This is a template for the Valuedesk Excel Importer.";
    instructionsSheet.getCell("B2").font = { size: 16, bold: true, color: { argb: "FF063C5B" } };
    instructionsSheet.getRow(2).height = 30;
    instructionsSheet.getCell("B4").value = "Please do not change the sheet names or the table names.";
    instructionsSheet.getCell("B5").value = "Do not copy the Valuedesk sheet to another file as this will break lookups.";
    instructionsSheet.getCell("B6").value = "Do not change the column names or the order of the columns.";
    instructionsSheet.getCell("B7").value = "This file is meant to be used in Microsoft Excel. Use of other software is not supported.";
    instructionsSheet.getCell("B8").value = "Please make sure that you only enter ids that you have edit access to.";
    switch (templateType) {
        case TemplateType.MeasureFields:
            instructionsSheet.getCell("B9").value =
                "Field values are based on the time this template was created. To get the latest values, please generate a new template.";
            instructionsSheet.getCell("B10").value = "Only specified fields will be updated. Keep the other columns empty.";
            instructionsSheet.getCell("B11").value = "Empty Ids will create new measures.";
            break;
        case TemplateType.MeasureEffects:
            instructionsSheet.getCell("B9").value =
                "Field values are based on the time this template was created. To get the latest values, please generate a new template.";
            instructionsSheet.getCell("B10").value = "Empty effect values will be skipped. An 'x' will delete the effect.";
            break;
        case TemplateType.Opps:
            instructionsSheet.getCell("B9").value = "Importing of fields is not supported yet.";
            break;
        default:
    }

    // Valuedesk sheet with the table
    const importSheet = workbook.addWorksheet("Valuedesk", {
        properties: { tabColor: { argb: "FF063C5B" } },
        views: [{ state: "frozen", xSplit: frozenColumns, ySplit: 1, showGridLines: true }],
    });

    createImportTable(importSheet, columnDefinitions);

    // Config sheet with mappings and other settings
    const configSheet = workbook.addWorksheet("vd_config", {
        properties: { tabColor: { argb: "FFF3902A" } },
        state: hidden,
    });

    createConfigTable(configSheet, columnDefinitions, templateType, measureConfigId);

    // We don't protect the sheets as they are hidden on production
    // Write the workbook to a buffer and return it as a blob
    const buffer = await workbook.xlsx.writeBuffer();
    return new Blob([buffer], { type: ExcelAppType });
}

function createBasicWorkbook() {
    const workbook = new ExcelJS.Workbook();

    workbook.creator = "Valuedesk Excel Importer";
    workbook.calcProperties.fullCalcOnLoad = true;

    return workbook;
}

function createImportTable(sheet: Worksheet, columnDefinitions: ColumnDefinition[]) {
    // Create array with empty strings for the first row
    const firstRow = Array.from({ length: columnDefinitions.length }, () => "");
    // Create array with column definitions for the table
    const tableColumns = columnDefinitions.map((colDef) => ({
        name: colDef.name, // Human readable name for the column
    }));

    // Add a Excel table to the sheet that allows adding new rows with validations.
    sheet.addTable({
        name: "vdtable",
        // displayName: "VD Data", // Don't set! This causes corrupt Excel file due to bug in ExcelJS
        ref: "A1",
        headerRow: true,
        totalsRow: false,
        style: {
            theme: "TableStyleMedium9",
            showRowStripes: true,
        },
        columns: tableColumns,
        rows: [firstRow],
    });

    columnDefinitions.forEach((element, index) => {
        // Add data validation
        switch (element.type) {
            case "id":
                sheet.getCell(2, index + 1).dataValidation = {
                    type: "whole",
                    operator: "greaterThan",
                    formulae: [0],
                    allowBlank: true,
                    errorTitle: "Invalid ID",
                };
                break;
            case "requiredId":
                sheet.getCell(2, index + 1).dataValidation = {
                    allowBlank: false,
                    type: "whole",
                    operator: "greaterThan",
                    formulae: [0],
                    errorTitle: "ID is required",
                    errorStyle: "error",
                    error: "ID is required. Please enter a valid ID.",
                    showErrorMessage: true,
                };
                break;
            case "title":
                {
                    // Can only be done with conditional formatting not data validation
                    const prevCellAddress = sheet.getCell(2, index).address;
                    const cellAddress = sheet.getCell(2, index + 1).address;
                    sheet.addConditionalFormatting({
                        ref: `${cellAddress}:${cellAddress}`,
                        rules: [
                            {
                                type: "expression",
                                formulae: [`AND(ISBLANK(${prevCellAddress}),ISBLANK(${cellAddress}))`],
                                style: { fill: { type: "pattern", pattern: "solid", bgColor: { argb: "FFFF0000" } } },
                                priority: 1,
                            },
                        ],
                    });
                    sheet.getCell(2, index + 1).dataValidation = {
                        type: "textLength",
                        operator: "greaterThan",
                        formulae: [0],
                        allowBlank: true,
                    };
                }
                break;
            case "lookup":
            case "lookuplist":
                addCellValidation(sheet, 2, index + 1, element.values ?? []); // We should have values for lists
                break;
            default:
        }

        // Allow editing cells - this will duplicate to new rows if using standard Excel functionality
        sheet.getCell(2, index + 1).protection = {
            locked: false,
        };
    });
}

function addCellValidation(sheet: Worksheet, row: number, col: number, values: string[]) {
    // Excel file will be broken if there are no values in the validation
    if (values.length === 0) {
        return;
    }

    const workbook = sheet.workbook;
    // Generate short sheet name like Valuedesk_CustomValidation_COL - must be <= 31 chars
    const validationSheetName = `vd_cv_${col}`;

    // Add the validation sheet with the values
    const validationSheet = workbook.addWorksheet(validationSheetName, {
        state: hidden,
    });
    validationSheet.insertRows(
        1,
        values.map((value) => [value]),
    );

    // Add the validation to the cell
    const column = "A";
    const rows = values.length;
    sheet.getCell(row, col).dataValidation = {
        type: "list",
        allowBlank: true,
        formulae: [`${validationSheetName}!$${column}$1:$${column}$${rows}`],
        errorStyle: "error",
        error: "Invalid value. Please select a value from the list.",
        showErrorMessage: true,
    };
}

function createConfigTable(sheet: Worksheet, columnDefinitions: ColumnDefinition[], templateType: TemplateType, measureConfigId?: number) {
    sheet.addRow([ConfigTemplateTypeKey, templateType]);

    if (measureConfigId !== undefined) {
        sheet.addRow([ConfigMeasureConfigIdKey, measureConfigId]);
    }

    sheet.addRow([ConfigFieldNamesKey].concat(columnDefinitions.map((colDef) => colDef.name)));
    sheet.addRow([ConfigFieldFieldsKey].concat(columnDefinitions.map((colDef) => colDef.field)));
}
